.background-brasa {
    position: relative;
    background: #548E40;
    background: linear-gradient(0deg, rgba(56, 56, 56, 0.3), rgba(30, 30, 28, 0.3)), url("../images/brasa-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.background-brasa .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 40px 50px;
    width: 30%;
    text-align: center;
    box-shadow: 0 0 10px 0 #555;
}

.background-brasa .box .text-instruction {
    text-align: left;
    margin-bottom: 100px;
}

.background-brasa .box .text-instruction h1 {
    font-weight: 800;
}

.background-brasa .box .google-login {
    width: 48%;
    padding: 10px 15px;
    text-align: center;
    border: none;
    border-radius: 25px;
    background: #1E1E1C;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: 0.4s;
    line-height: 1.5em;
}

.background-brasa .box .google-login:hover {
    background: #383838;
    transition: 0.4s;
}

.background-brasa .box .google-login img {
    float: left;
    margin-right: 10px;
    height: 1.5em;
}

.background-brasa .box .google-login span {
    height: 100%;
    color: white;
    font-weight: 800;
}

.error{
    display: none;
    margin-top: 36px;
    color: red;
}

@media (max-width: 1024px) {
    .background-brasa .box {
        width: 60%;
    }
    .background-brasa .box .google-login {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .mobile-margin{
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

@media (max-width: 578px) {
    .background-brasa .box {
        width: 90%;
    }
    .background-brasa .box .google-login {
        width: 60%;
    }
}