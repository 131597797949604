* {
    font-family: 'Lato', sans-serif;
    color: #1E1E1C;
}

.text-white{
    color: white;
}

.text-black{
    color: black;
}

.text-green{
    color: #548E40;
}

.bg-blue{
    background-color: #1887C9;
}

.bg-yellow{
    background-color: #FAE108;
}

.bg-green{
    background-color: #78B243;
}

.bg-dark-blue{
    background-color: #184274;
}

.bg-dark-yellow{
    background-color: #F3C515;
}

.bg-dark-green{
    background-color: #548E40;
}

.bg-white{
    background-color: #F6F7F7;
}

.bg-light-grey{
    background-color: #D9D9D9;
}

.bg-grey{
    background-color: #383838;
}

.bg-dark-grey{
    background-color: #1E1E1C;
}

.middle-transform{
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
}

.error-page{
    height: 100vh;
}

.error-page h1{
    font-weight: 800;
}

.error-page h2{
    margin-top: 2em;
    font-weight: 600;
    font-size: 3em;
}

@media print{
    nav, .excel-column{
        display: none;
    }

    footer{
        display: none;
    }

    .avoid-break {
        break-inside: avoid;
    }
}