main {
    background-color: #F6F7F7;
}

.main.container{
    min-height: 100vh;
    margin-top: 20px;
}

.main.container h1{
    font-weight: bold;
    margin-bottom: 40px;
}

hr{
    background-color:  #383838;
    height: 3px;
}

.text-sm-right{
    text-align: right;
}

.card-body{
    border-radius: 10px;
    border: none;
}

.card{
    border: none;
}

.shadow-hover:hover .number-circle{
    box-shadow: 0 0 10px 0 #1E1E1C;
}

.shadow-hover:hover .card-body{
    box-shadow: 0 0 4px 0 #1E1E1C;
}

.number-circle {
    background: #fff;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    font-weight: bold;
    line-height: 8vw;
    text-align: center;
    width: 8vw;
    font-size: 30pt;
}

.desc-stats{
    font-size: 2em;
    line-height: 8vw;
    color: white;
    font-weight: bold;
    margin: 0;
}

.list-times p{
    font-size: 2em;
    line-height: 5vw;
    color: #1E1E1C;
    font-weight: bold;
}

.list-times .card-body{
    background-color: #D9D9D9;
    border: 4px solid #F6F7F7;
}

.list-times .card-body:hover{
    border: 4px solid #383838;
}

.submit-form{
    background-color: #1E1E1C;
    width: 50%;
    border: none;
}

.submit-form:hover{
    background-color: #383838;
}

.statis-link{
    display: block;
    text-align: center;
    color: #383838;
    font-size: 2em;
    line-height: 8vw;
    font-weight: bold;
    padding: 1.25rem;
}

.statis-link:hover{
    color: #1E1E1C;
}

.statis-link i{
    padding-left: 15px;
}

.time-collpase-title{
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    color: #ffffff;
    font-size: 2em;
    align-items: center;
}

.time-collpase-title:focus, .time-collpase-title:active{
    box-shadow: none;
}

.time-collpase-title i{
    color: #ffffff;
    font-weight: 900;
}

.table .thin-column{
    text-align: center;
    width: 10%;
}

.select-filter-stats{
    padding: 15px;
    border: #383838 4px solid;
    border-radius: 15px;
    font-size: 1.2em;
    width: 100%;
}

.select-filter-stats:focus{
    outline: none;
}

.exporters{
    cursor: pointer;
    font-size: 2.2em;
    color: inherit;
}

#delete-modal{
    background: rgba(10,10,10,0.8);
}

.linkSelected{
    font-size: 1.5em;
}

.copy-to-clipboard{
    cursor: pointer;
}

.table.referers{
    table-layout: fixed;
}

.table.referers td{
    white-space: nowrap;
    overflow: hidden;
}

.col-width-80{
    width: 80%;
}

@media (max-width: 768px) {
    .number-circle {
        line-height: 20vw;
        width: 20vw;
    }
    .desc-stats{
        font-size: 1.5em;
    }

    .list-times p{
        color: white;
    }

    .list-times .card-body{
        background-color: #1E1E1C;
    }
}

@media(max-width: 575px){
    .text-sm-right{
        text-align: inherit;
    }
}

@media print{
    .force-margin-ciclo canvas{
        width: 100% !important;
    }
    .force-margin-ciclo{
        margin-top: 34em !important;
    }
    .force-margin-top5{
        margin-top: 12em !important;
    }
}